import React from "react";
import {Modal} from 'react-bootstrap'
import './confirmationModalComp.css'

const ConfirmationModalComp = ({ isOpen, handleCloseModal, handleSubmit, title, message, submitButtonLabel, optionalThirdButtonLabel, optionalThirdOption, setOptionalThirdButtonLabel}) => {

    const handleOnHide = () => {
        handleCloseModal()
        setOptionalThirdButtonLabel("")
    }

return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpen}
                onHide={handleOnHide}
            >
                <Modal.Header>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-text' >
                    {message.map((x, i) => (
                        <p style={{ textAlign: "center" }} key={i}>
                            {x}
                        </p>
                    ))}
                </Modal.Body>
                <Modal.Footer className='modal-buttons'>
                    <button style={{ backgroundColor: "red", color: "white" }} onClick={(e) => { handleSubmit(e) }}>{submitButtonLabel}</button>

                    {optionalThirdButtonLabel && optionalThirdButtonLabel !== "" &&
                        <button style={{ backgroundColor: "rgb(0, 122, 255)", color: "white" }} onClick={(e) => { optionalThirdOption(e) }}>{optionalThirdButtonLabel}</button>
                    }

                    <button style={{ backgroundColor: "lightGray" }} onClick={() => handleCloseModal()}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmationModalComp;
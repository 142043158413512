import React from "react";
import './tempMessageModalComp.css'
import check from './check.svg'
import { Modal } from "react-bootstrap";

const TempMessageModalComp = ({ isOpen, isSuccessMessage, message }) => {

    return (
        <>
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpen}
            >
                <Modal.Body style={{ textAlign: 'center' }}>
                    {isSuccessMessage &&
                        <div>
                            <img src={check} alt="check" />
                            {message}
                        </div>
                    }
                    {!isSuccessMessage &&
                        <div>{ message }</div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
export default TempMessageModalComp;
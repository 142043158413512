import React, { useEffect, useState, } from 'react';
import { Form, Modal } from 'react-bootstrap';

const LabFilterModal = (props) => {
    const {
        isOpen,
        handleCloseModal, 
        title,
        listItems,
        filter,
        filterList,
        handleFiltering,
    } = props;

    const [taskState, setTaskState] = useState(filterList);

    const handleOnHide = () => {
        handleCloseModal(!isOpen)
        filter("")
    }

    const handleSave = () => {
        handleCloseModal(!isOpen)
    }

    const isChecked = (id) => {
        return (taskState.indexOf(id.toString()) > -1);
    }

    const handleFormUpdate = (event) => {
        if (event.target.value === 'All') {
            filter("")
        } else {
            filter(event.target.value);
        }
    }

    useEffect(() => {
        if(filterList === "") {
            setTaskState("All")
        } else {
            setTaskState(filterList)
        }
    }, [filterList]);

    return (
        <Modal
            show={isOpen}
            onHide={handleOnHide}
            size="sm"
            centered
            backdrop="static"
        >
            <Modal.Header><h6>{title}</h6></Modal.Header>
            <div>
                <Modal.Body className="device-filter-modal">
                    {listItems.map((row, index) =>
                        <Form.Check
                            key={index}
                            type="radio"
                            label={row}
                            name="taskState"
                            id={'taskStaterow' + index}
                            value={row}
                            onChange={handleFormUpdate}
                            checked={isChecked(row)}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button style={{ backgroundColor: "rgb(0, 122, 255)", color: "white", border: "none" }} onClick={(e) => {handleSave(); handleFiltering();}}>
                        Apply
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default LabFilterModal;
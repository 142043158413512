import React, { useState } from "react";
import Archive from "./archive";
import Capture from "./capture";
import Recent from "./recent";
import { Navigate } from 'react-router-dom';
import { getAccessToken, getEmail } from '../../services/User';
import CloseCircle from '../svgs/CloseCircle.svg';
import './tabs.css';

const tabs = [
    {
        key: 'capture',
        label: 'Capture'
    }, {
        key: 'recent',
        label: 'Recent'
    }, {
        key: 'archive',
        label: 'Archive'
    }
]



const SampleCapture = () => {
    const [activeTab, setActiveTab] = useState('capture');
    const token = getAccessToken();
    const userEmail = getEmail();
    const [showModal, setShowModal] = useState({
        image: '',
        visible: false,
    });
    const handleShowModal = image => {
        setShowModal({
            image,
            visible: true,
        });
    };
    const handleCloseModal = () => {
        setShowModal({
            image: '',
            visible: false,
        });
    };

    if (token === null || token === '') {
        return <Navigate to='/login' />
    }

    return (
        <div>
            {showModal.visible && (
                <div className="sample-capture-modal">
                    <div
                        aria-hidden="true"
                        className="close-icon"
                        onClick={() => handleCloseModal()}
                    >
                        <img src={CloseCircle} alt="CloseCircle"/>
                    </div>
                    <div
                        aria-hidden="true"
                        className="sample-capture-modal-wrap"
                        onClick={() => handleCloseModal()}
                    >
                        <div className="inner">
                            <img alt="capture" src={showModal.image} className="capture-image" />
                        </div>
                    </div>
                </div>
            )}
            <div className="action-pills">
                {tabs.map(({ key, label }) => (
                    <div className={`pill ${activeTab === key ? 'selected' : ''}`}
                        aria-hidden="true"
                        key={key}
                        onClick={() => setActiveTab(key)}
                    >
                        {label}
                    </div>
                ))}
            </div>
            <div className="tab-content">
            {activeTab === 'capture' && (
                <Capture
                    userEmail={userEmail}
                    handleShowModal={handleShowModal}
                />
            )}
            {activeTab === 'recent' && (
                <Recent
                    userEmail={userEmail}
                    handleShowModal={handleShowModal}
                />
            )}
            {activeTab === 'archive' &&
                <Archive
                    userEmail={userEmail}
                />
            }
            </div>
        </div>
    )

}

export default SampleCapture;
import React from 'react';
import { Route, Routes, } from 'react-router-dom';

import LoginComponent from './LoginComponent';
import { SampleCapture, SampleView } from './SampleCapture';
import { ServiceHeader } from './components';

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => {

    return (

        <div style={{ background: "rgb(240,242,244", height: "100vh" }}>
            <ServiceHeader
                needsLogOut={true}
                menuItems={[{
                    url: "",
                    title: ""
                    // url: "https://intranet.servitech.com",
                    // title: "Return to Intranet"
                }]}
                //title={process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_SERVICE_TITLE: `${process.env.REACT_APP_SERVICE_TITLE} STAGING`}
                title={`Sample Capture STAGING`}
            />
            <div className="content-routes">
                <Routes>
                    <Route path="login" element={<LoginComponent />} />
                    <Route exact path="/" element={<SampleCapture />} />
                    <Route
                        element={<SampleView/>}
                        exact
                        path="/sample-capture/view/:fiscalYear/:lab/:labNumber/:labNumberEnd/:sampleType"
                    />
                </Routes>
            </div>
        </div>
    );
}

export default App;

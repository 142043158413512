import React from "react";
import Webcam from "react-webcam";
import Maximize from "./maximize.svg";
import Plus from "./plus.svg";
import Trash from "./trash.svg";
import './photoGridComp.css';

const PhotoGridComp = ({
    images,
    handlePhotoDelete,
    handlePhotoEnlarge,
    readOnly,
    videoConstraints,
    webcamRef,
    showAutoPhotoTools,
    fileInput,
    fileInputKey,
    handlePhotoAddToGridFileInput,
    errors
}) => {

    function doubleclickscreen(e) {
        e.preventDefault()
        e.target.click()
        if (e.touches.length > 1) {
            e.preventDefault()
            e.target.click()
        }
    }

    if (readOnly) {
        return (
            <div className={showAutoPhotoTools ? "sample-capture-image-grid-autopics" : "sample-capture-image-grid"}>
                {images.map(({ id, url }) => (
                    <div className="capture-wrapper" key={id}>
                        <img
                            alt="capture"
                            aria-hidden="true"
                            onClick={() => handlePhotoEnlarge(url)}
                            src={`${url}`}
                        />
                        <div className="icon-wrapper">
                            <div
                                aria-hidden="true"
                                onClick={() => handlePhotoEnlarge(url)}
                            >
                                <img src={Maximize} alt="maximize"/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            {showAutoPhotoTools &&
                <div className='auto-grid-container'>
                    <div className={showAutoPhotoTools ? "sample-capture-image-grid-autopics" : "sample-capture-image-grid"}>
                        {images.length > 0 && images.map(({ id, image, url }) => (
                            <div className="capture-wrapper" key={id}>
                                <img
                                    alt="capture"
                                    aria-hidden="true"
                                    onClick={() => handlePhotoEnlarge(image)}
                                    src={url
                                        ? `${url}}`
                                        : image}
                                />
                                <div className="icon-wrapper">
                                    <div
                                        aria-hidden="true"
                                        onClick={() => handlePhotoEnlarge(image)}
                                    >
                                        <img src={Maximize} alt="maximize"/>
                                    </div>
                                    <div
                                        aria-hidden="true"
                                        onClick={() => handlePhotoDelete(id)}
                                    >
                                        <img src={Trash} alt="trash"/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="cam-preview-div" onDoubleClick={(e) => doubleclickscreen(e)} onTouchStart={(e) => doubleclickscreen(e)}>
                        <Webcam
                            forceScreenshotSourceSize
                            height={360}
                            width={640}
                            audio={false}
                            screenshotQuality={1}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        />
                    </div>
                </div>
            }
            {!showAutoPhotoTools &&
                <div className="sample-capture-image-grid">
                    <label
                        className={errors && errors.images && errors.images.error
                            ? 'file-upload has-error'
                            : 'file-upload'}
                        htmlFor="capture-button"
                    >
                        <div className="icon">
                            <img src={Plus} alt="plus"/>
                        </div>
                        <div>Add Photo</div>
                        <input
                            accept="image/*"
                            capture
                            hidden
                            id="capture-button"
                            key={fileInputKey}
                            ref={fileInput}
                            onChange={e => handlePhotoAddToGridFileInput(e)}
                            type="file"
                        />
                    </label>
                    {images.length > 0 && images.map(({ id, image, url }) => (
                        <div className="capture-wrapper" key={id}>
                            <img
                                alt="capture"
                                aria-hidden="true"
                                onClick={() => url ? handlePhotoEnlarge(url) : handlePhotoEnlarge(image)}
                                src={url
                                    ? `${url}`
                                    : image}
                            />
                            <div className="icon-wrapper">
                                <div
                                    aria-hidden="true"
                                    onClick={() => url ? handlePhotoEnlarge(url) : handlePhotoEnlarge(image)}
                                >
                                    <img src={Maximize} alt="maximize"/>
                                </div>
                                <div
                                    aria-hidden="true"
                                    onClick={() => handlePhotoDelete(id)}
                                >
                                    <img src={Trash} alt="trash"/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
}
export default PhotoGridComp;
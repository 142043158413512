import React from "react";
import AdvanceOptionsModal from "./advancedOptionsModal";
import Code from './Code.svg'
import './labFormInputsComp.css';

const LabFormInputsComp = ({
    yearsList,
    labList,
    sampleTypeList,
    errors, formData,
    onChange,
    preventMinus,
    withLocksAndRange,
    handleAdvanceOptionsLabel,
    advanceOptionsLabel,
    advanceOptionsModal,
    setAdvanceOptionsModal,
    handleSetAdvanceOptions }) => {

    let showLocks = false
    if (Object.keys(withLocksAndRange || {}).length > 0) {
        showLocks = true;
    }
    const handleCloseModal = () => {
        setAdvanceOptionsModal(false)
    }
    const openAdvanceOptionsModal = (e) => {
        e.preventDefault();
        setAdvanceOptionsModal(true)
    }

    return (
        <>
            <div className="sample-capture-action-wrapper">
                <div className={errors && errors.fiscalYear && errors.fiscalYear.error ? "select-wrapper has-error" : "select-wrapper"}>
                    <div>
                        <label className="sample-option-label">
                            Fiscal Year
                        </label>
                    </div>
                    <select
                        name="fiscalYear"
                        onChange={e => onChange(e)}
                        style={{ width: "100%", marginBottom: showLocks ? "12px" : "0px" }}
                        value={formData.fiscalYear}
                    >
                        <option disabled value="">
                            Select Year
                        </option>
                        {yearsList.map((x, index) => (
                            <option value={x} key={index}>{x}</option>
                        ))}
                    </select>
                    {showLocks &&
                        <div className="lock-wrapper">
                            <button
                                onClick={(e) => { withLocksAndRange.fiscalYearLocked.setter(!withLocksAndRange.fiscalYearLocked.value); onChange(e) }}
                                style={{ backgroundColor: withLocksAndRange.fiscalYearLocked.value ? "rgb(0, 122, 255)" : "rgb(248, 248, 248)", color: withLocksAndRange.fiscalYearLocked.value ? "white" : "black" }}
                            >
                                {withLocksAndRange.fiscalYearLocked.value ? 'Locked' : 'Lock'}
                            </button>
                        </div>
                    }
                </div>
                <div className={errors && errors.lab && errors.lab.error ? "select-wrapper has-error" : "select-wrapper"}>
                    <div>
                        <label className="sample-option-label">Lab</label>
                    </div>
                    <select
                        name="lab"
                        onChange={e => onChange(e)}
                        style={{ width: "100%", marginBottom: showLocks ? "12px" : "0px" }}
                        value={formData.lab}
                    >
                        <option disabled value="">
                            Select Lab
                        </option>
                        {labList.map((x, index) => (
                            <option value={x} key={index}>{x}</option>
                        ))}
                    </select>
                    {showLocks &&
                        <div className="lock-wrapper">
                            <button
                                onClick={(e) => { withLocksAndRange.labLocked.setter(!withLocksAndRange.labLocked.value); onChange(e, "labLocked") }}
                                style={{ backgroundColor: withLocksAndRange.labLocked.value ? "rgb(0, 122, 255)" : "rgb(248, 248, 248)", color: withLocksAndRange.labLocked.value ? "white" : "black" }}
                            >
                                {withLocksAndRange.labLocked.value ? 'Locked' : 'Lock'}
                            </button>
                        </div>
                    }
                </div>
                <div className={errors && errors.sampleType && errors.sampleType.error ? "select-wrapper has-error" : "select-wrapper"}>
                    <div>
                        <label className="sample-option-label">Sample Type</label>
                    </div>
                    <select
                        name="sampleType"
                        onChange={e => onChange(e)}
                        style={{ width: "100%", marginBottom: showLocks ? "12px" : "0px" }}
                        value={formData.sampleType}
                    >
                        <option disabled value="">
                            Select Sample Type
                        </option>
                        {sampleTypeList.map((x, index) => (
                            <option value={x} key={index}>{x}</option>
                        ))}
                    </select>
                    {showLocks &&
                        <div className="lock-wrapper">
                            <button
                                onClick={(e) => { withLocksAndRange.sampleTypeLocked.setter(!withLocksAndRange.sampleTypeLocked.value); onChange(e, "sampleTypeLocked") }}
                                style={{ backgroundColor: withLocksAndRange.sampleTypeLocked.value ? "rgb(0, 122, 255)" : "rgb(248, 248, 248)", color: withLocksAndRange.sampleTypeLocked.value ? "white" : "black" }}
                            >
                                {withLocksAndRange.sampleTypeLocked.value ? 'Locked' : 'Lock'}
                            </button>
                        </div>
                    }
                </div>
                <div className="sample-capture-lab-number-wrapper" >
                    <div className="lab-range">
                    <div className="lab-number-div">
                        <div>
                            <label className="sample-option-label">{showLocks && withLocksAndRange.labRange.value ? 'Lab Range Start' : 'Lab Number'}</label>
                        </div>
                        <input
                            error={errors && errors.labNumber && errors.labNumber.error ? "true" : "false"}
                            type="number"
                            label={showLocks && withLocksAndRange.labRange.value ? 'Lab Range Start' : 'Lab Number'}
                            name="labNumber"
                            onChange={e => onChange(e)}
                            value={formData.labNumber}
                            onKeyPress={e => preventMinus(e)}
                            className="lab-number-input"
                        />
                    </div>
                    {showLocks && withLocksAndRange.labRange.value && (
                        <div className="lab-number-div">
                            <label  className="sample-option-label">End</label>
                            <input
                                error={errors && errors.labNumber && errors.labNumber.error ? "true" : "false"}
                                type="number"
                                label="Range End"
                                name="labNumberEnd"
                                onChange={e => onChange(e)}
                                value={formData.labNumberEnd}
                                onKeyPress={e => preventMinus(e)}
                                className="lab-number-input"
                            />
                        </div>
                    )}
                    </div>
                    {showLocks && (
                        <div className="lock-wrapper with-input">
                            <button
                                className="advance-options-button"
                                // variant="outline-dark"
                                onClick={(e) => { openAdvanceOptionsModal(e); }}
                                style={{position: "relative"}}
                            >
                                {advanceOptionsLabel}
                                <img src={Code} alt="arrows" style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    right: "6px"
                                }}/>
                            </button>
                            <AdvanceOptionsModal
                                isOpen={advanceOptionsModal}
                                handleCloseModal={handleCloseModal}
                                openAdvanceOptionsModal={openAdvanceOptionsModal}
                                handleAdvanceOptionsLabel={handleAdvanceOptionsLabel}
                                advanceOptionsLabel={advanceOptionsLabel}
                                setModalOpen={setAdvanceOptionsModal}
                                handleSetAdvanceOptions={handleSetAdvanceOptions}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export default LabFormInputsComp;
import React, { useEffect, useState, } from 'react';
import { Form, Modal } from 'react-bootstrap';
const listItems = [
    'Lab Number Auto Advance',
    'Range',
    'Auto Pictures'
];

const AdvanceOptionsModal = (props) => {
    const {
        advanceOptionsLabel,
        handleAdvanceOptionsLabel,
        isOpen,
        setModalOpen,
        handleSetAdvanceOptions
    } = props;

    const [taskState, setTaskState] = useState(advanceOptionsLabel);

    const handleOnHide = () => {
        setModalOpen(!isOpen);
    }

    const handleSave = () => {
        setModalOpen(!isOpen);
    }

    const isChecked = (id) => {
        return (taskState.indexOf(id.toString()) > -1);
    }

    const handleFormUpdate = (event) => {
        handleAdvanceOptionsLabel(event.target.value);
    }
    useEffect(() => {
        setTaskState(advanceOptionsLabel)
    }, [advanceOptionsLabel]);

    return (
        <Modal
            show={isOpen}
            onHide={handleOnHide}
            size="sm"
            centered
            backdrop="static"
        >
            <Modal.Header><h6>Advance Options</h6></Modal.Header>
            <div>
                <Modal.Body className="device-filter-modal">
                    {listItems.map((row, index) =>
                        <Form.Check
                            key={index}
                            type="radio"
                            label={row}
                            name="taskState"
                            id={'taskStaterow' + index}
                            value={row}
                            onChange={handleFormUpdate}
                            checked={isChecked(row)}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={(e) => { handleSave(); handleSetAdvanceOptions(taskState) }}
                        style={{ backgroundColor: "rgb(0, 122, 255)", color: "white", border: "none" }}
                    >
                        Apply
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default AdvanceOptionsModal;
import React from "react";
import closeCircle from './closeCircle.svg'
import close from './x.svg'
import './missingInfoModalComp.css'

const MissingInfoModalComp = ({ isOpen, handleCloseModal, missingInfo }) => {

    return (
        <>
            {isOpen &&
                <div className="missing-info">
                    <div
                        aria-hidden="true"
                        className="title-wrapper"
                        onClick={() => handleCloseModal()}
                    >
                        <img src={closeCircle} alt="closeCircle" />
                        <div>Missing Info</div>
                        <img src={close} alt="closeCircle" />
                    </div>
                    <ul className="missing-info-ul">
                        {Object.entries(missingInfo).map(el => el[1].message
                            && <li key={el[1].message}>{el[1].message}</li>)}
                    </ul>
                </div>
            }
        </>
    )
}
export default MissingInfoModalComp;
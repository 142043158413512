export const getEmail = () => {
  const email = localStorage.getItem('email');
  return (email);
}
export const setEmail = (email) => {
  localStorage.setItem('email', email);
}

export const clearEmailToken = () => {
  localStorage.removeItem('email');
}


export const getAccessToken = () => {
  const token = localStorage.getItem('token');
  return (token);
}
export const setAccessToken = (token) => {
  localStorage.setItem('token', token);
}

export const clearAccessToken = () => {
  localStorage.removeItem('token');
}
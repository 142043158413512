import React from "react";
import { Link } from 'react-router-dom';
import ServitechLogoColor from './ServiTechColor.svg';
import './serviceHeader.css';

import { clearEmailToken, clearAccessToken } from './user.js';

//This component is the header for the service it include a logo, title, and a back button
const ServiceHeader = (props) => {
    // The TopNav component expects a couple prop:
    // title: should be a string that will be displayed
    // as the title of the service

    // menuItems (optional prop): an array of objects that includes 
    // links and titles that will be used in the menu list
    // ex.) [{url:"https://somewhere.com", title:"Go There"}]

    // needsLogOut (optional prop): expecting a boolean.
    // If the service uses googleAuth to log in then the boolean should be true.
    // if the service uses a cookie then it should be false.
    const { title, menuItems, needsLogOut } = props;

    const handleGoBack = () => {
        if (window.history.length > 1) {
            window.history.back()
        } else {
            window.close();
        }

    }

    const handleLogOut = async (e) => {
        //console.log('logout')
        e.preventDefault()
        clearEmailToken()
        clearAccessToken()
    }

    return (
            <header>
                <div className="ST-navbar" >
                    <div className='col-2 col-sm-3 center-txt' style={{ justifyContent: "left"}}>
                        {/* eslint-disable-next-line */}
                        <div href="#" style={{paddingLeft: "2vw"}}><img src={ServitechLogoColor} height="40rem" /></div>
                    </div>
                    <div className="col-8 col-sm-6 center-txt" style={{ justifyContent: "center" }}>
                        <div style={{ fontSize: "1.2rem", textAlign: "center" }}>
                            <b>{title}</b>
                        </div>
                    </div>
                    {!menuItems && !needsLogOut &&
                        <div className="col-2 col-sm-3 center-txt" style={{ justifyContent: "right" }}>
                            <button onClick={handleGoBack} className='back-link'><b>Back</b></button>
                        </div>
                    }
                    {!menuItems && needsLogOut &&
                        <div className="col-2 col-sm-3 center-txt" style={{ justifyContent: "right" }}>
                            <button onClick={handleGoBack} className='back-link'><b>Logout</b></button>
                        </div>
                    }
                    {menuItems &&
                        <div className="col-2 col-sm-3 center-txt" style={{ justifyContent: "right", paddingRight: "2vw"}}>
                            <div data-bs-toggle="dropdown">
                                <i className="bi bi-three-dots" style={{ "fontSize": "1.5rem", "color": "#000000", "cursor": "pointer" }}></i>
                            </div>
                            <ul className="me-0 dropdown-menu dropdown-menu-end">
                                {menuItems.map((x, index) =>
                                    <li key={index}><a className="dropdown-item" href={x.url}>{x.title}</a></li>
                                )}
                                {needsLogOut &&
                                    <>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li className="dropdown-item" onClick={(e) => handleLogOut(e)}>
                                            <Link to="/login" style={{ color: "black", textDecoration: "none" }}>
                                                Logout
                                            </Link>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                    }
                </div>
            </header>
    )
}
export default ServiceHeader;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { setEmail, setAccessToken, getEmail, getAccessToken } from './services/User.js';

export default function LoginComponent(props) {
    const email = getEmail();
    const accessToken = getAccessToken();
    const navigate = useNavigate()

    const [loginError, setLoginError] = useState(false);
    const [loginLoading, setLoginLoading] = useState(null);

    const handleLoginFailure = () => {
        setLoginError(true);
        setLoginLoading(false);
        setTimeout(() => {
            setLoginError(false);
            setLoginLoading(null);
            navigate("/", { replace: true })
        }, 3000);
    }

    const handleOnRequest = () => {
        setLoginLoading(true);
    }

    const handleLogin = async (response) => {
        handleOnRequest()
        const {
            credential = '',
        } = response;

        if (credential) {
            const fetchLogIn = await fetch('https://google-auth-node-login-bjudfyf5za-uc.a.run.app/login', { method: "POST", headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ credential: credential, GOOGLE_CLIENT_ID: "495656309447-taph324qeq87gu34de640oshvptqgkfr.apps.googleusercontent.com" }) });
            // const fetchLogIn = await fetch('http://localhost:4000/login', { method: "POST", headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ credential: credential, GOOGLE_CLIENT_ID: "495656309447-l15qpfuru9gt0bs335oo1c4vj6ctbv5u.apps.googleusercontent.com" }) });
            const fetchLogInJson = await fetchLogIn.json();
            // console.log(fetchLogInJson);
            if (fetchLogInJson && fetchLogInJson.user) {
                if (fetchLogInJson.user.email.includes('@servitech')) {
                    setEmail(fetchLogInJson.user.email);
                    setAccessToken(fetchLogInJson.user.accessToken)
                    setLoginLoading(false);
                    navigate("/");
                } else {
                    handleLoginFailure();
                }
            } else {
                handleLoginFailure();
            }
        } else {
            handleLoginFailure();
        }
    }

    useEffect(() => {
        if (accessToken && email) {
            navigate("/")
        } else if (window.google) {
            window.google.accounts.id.initialize({
                client_id: '495656309447-taph324qeq87gu34de640oshvptqgkfr.apps.googleusercontent.com',
                callback: handleLogin
            });
            window.google.accounts.id.renderButton(
                document.getElementById("googleSignInButtonDiv"),
                { theme: "outline", size: "large", shape: "rectangle", logo_alignment: "left", text: "signin_with" }  // customization attributes
            );
            window.google.accounts.id.prompt();
        }
        // eslint-disable-next-line
    }, [])

    if (loginLoading) {
        return (
            <div className="login-wrapper">
                Loading ...
            </div>
        );
    }

    return (
        <div>
            {(!loginError || loginError === 'false') && (
                <div className="login-wrapper align-center">
                    <div id="googleSignInButtonDiv"></div>
                </div>
            )}
            {(loginError || loginError === 'true') && (
                <div className="login-wrapper align-center">Login failed, please try again with a servitech email.</div>
            )}
        </div>
    )
}
import React, { useRef, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import chevronDown from '../../svgs/chevronDown.svg';
import chevronUp from '../../svgs/chevronUp.svg';
import code from '../../svgs/code.svg';
import filterIcon from '../../svgs/filter.svg';
import '../tabs.css';

import { getEmail } from '../../../services/User';

import {
    ConfirmationModalComp,
    MissingInfoModalComp,
    LabFormInputsComp,
    TempMessageModalComp,
    PhotoGridComp,
    FilterModalComp
} from '../../../components';

const sampleCapGQLURI = 'https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql' //live GQL

const Recent = (props) => {

    const { handleShowModal } = props;
    const fileInput = useRef();
    const ref = useRef();
    const [images, setImages] = useState([]);
    const [errors, setErrors] = useState({});
    const [fileInputKey, setFileInputKey] = useState(1);
    const [editRow, setEditRow] = useState("");
    const [formData, setFormData] = useState("");
    const [loading, setLoading] = useState(false);
    const [photoRow, setPhotoRow] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    // const [showTempMessageModal, setShowTempMessageModal] = useState(false);
    const [filteredResources, setFilteredResources] = useState("");
    const [showImages, setShowImages] = useState("");
    const [capArrayChanged, setCapArrayChanged] = useState(false);
    const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
    const [labFilterLabel, setLabFilterLabel] = useState("");
    const [sampleTypeFilterLabel, setSampleTypeFilterLabel] = useState("");
    const [labFilterModal, setLabFilterModal] = useState(false);
    const [sampleTypeFilterModal, setSampleTypeFilterModal] = useState(false);
    const [labButtonLabel, setLabButtonLabel] = useState("");
    const [sampleTypeButtonLabel, setSampleTypeButtonLabel] = useState("");
    const [filterCombine, setFilterCombine] = useState("");
    const [labFilter, setLabFilter] = useState("");
    const [photoDeleteID, setPhotoDeleteID] = useState("");
    const [editing, setEditing] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [filterAfterEdit, setFilterAfterEdit] = useState(false);
    const [cookies, setCookies] = useCookies(["user"]);
    let s = [];

    async function getSampleCaptures() {
        setLoading(true);
        const getSampleCapturesQuery = `
            query GetSampleCaptures {
                getSampleCaptures {
                    sampleCaptures {
                        id
                        fiscalYear
                        lab
                        labNumber
                        photos {
                            id
                            token
                            url
                            photoVersion
                            s3ObjectUrl
                        }
                        sampleType
                    }
                }
            }`
        const getSampleCapturesQueryResponse = await fetch(sampleCapGQLURI, {
            method: 'POST',
            body: JSON.stringify({ query: getSampleCapturesQuery }),
            headers: { 'Content-type': 'application/json' }
        })
        const getSampleCapturesData = await getSampleCapturesQueryResponse.json();
        if (getSampleCapturesData && getSampleCapturesData.data && getSampleCapturesData.data.getSampleCaptures && getSampleCapturesData.data.getSampleCaptures.sampleCaptures) {
            setShowImages(getSampleCapturesData.data.getSampleCaptures.sampleCaptures);
            setFilterCombine(getSampleCapturesData.data.getSampleCaptures.sampleCaptures);
            setCapArrayChanged(true);
        } else {
            console.log("The query returned nothing.")
        }
        setLoading(false);
    }

    const handleCookie = () => {
        setCookies('labFilter', labFilterLabel, { path: '/' });
    }

    function getNestedStrings(obj, level) {
        if (obj) {
            if (level === 1) { s = [] }  // at parent level, start with an empty array
            const valArr = Object.values(obj);  // get array of object property values
            const strArr = valArr.filter((o) => { return (typeof o === 'string') });  // filter array for strings
            const objArr = valArr.filter((o) => { return (typeof o === 'object') });  // filter array for objects
            if (strArr.length > 0) { s = [...s, ...strArr]; };  // append strings to result array
            objArr.forEach((x) => { getNestedStrings(x, level + 1) });  // recurse through objects collecting child strings
            if (level === 1) { return s; };  // at parent level, return the string array
        }
    };

    const handleSearchKeyUp = (event) => {
        event.preventDefault();
        const searchString = event.target.value.toLowerCase();
        if (showImages) {
            if (searchString.length && sampleTypeFilterLabel === "All" && labFilterLabel === "All") {
                const res = (showImages.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                    return (getNestedStrings(x, 1).some((y) => {
                        return (y.toLowerCase().includes(searchString.toLowerCase()))
                    }))
                }));
                setFilteredResources(res)  // show result object(s)
            } else if (searchString.length && (sampleTypeFilterLabel !== "All" || labFilterLabel !== "All")) {
                const res = (filterCombine.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                    return (getNestedStrings(x, 1).some((y) => {
                        return (y.toLowerCase().includes(searchString.toLowerCase()))
                    }))
                }));
                setFilteredResources(res)  // show result object(s)
            } else if (!searchString.length && (sampleTypeFilterLabel !== "All" || labFilterLabel !== "All")) {
                setFilteredResources(filterCombine)
            } else {
                setFilteredResources(showImages)
            }
        } else {
            setFilteredResources(showImages)
        }
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.which > 57 || e.which === 32) {
            e.preventDefault();
        }
    };

    const handleFilterForLab = () => {
        ref.current.value = "";
        setSampleTypeFilterLabel("");
        const filterForLab = labFilterLabel.toLowerCase();
        if (filterForLab.length) {
            const res = (showImages.filter((x) => {
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(filterForLab.toLowerCase()))
                }))
            }));
            setFilteredResources(res);
            setFilterCombine(res);
            setLabFilter(res);
        } else {
            setFilteredResources(showImages);
        }
        handleCookie()
    }

    const handleFilterForSampleType = () => {
        ref.current.value = "";
        const filterForSampleType = sampleTypeFilterLabel.toLowerCase();
        if (filterForSampleType.length && !labFilterLabel.length) {
            const res = (showImages.filter((x) => {
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
                }))
            }));
            setFilteredResources(res)  // show result object(s)
            setFilterCombine(res)
        } else if ((filterForSampleType.length || filterForSampleType === "") && labFilterLabel.length) {
            const res = (labFilter.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
                }))
            }));
            setFilteredResources(res)  // show result object(s)
            setFilterCombine(res)
        } else {
            setFilteredResources(showImages)
        }
    }

    const handleEditRow = id => {
        setEditing(false);
        if (id === editRow) {
            return setEditRow("");
        }
        const rowToEdit = filteredResources.find(element => element.id === id)
        const imagesToEdit = rowToEdit.photos.map(element => ({ ...element, status: "KEEP" }))
        setFormData(rowToEdit)
        setImages(imagesToEdit)
        setPhotoRow("")
        return setEditRow(id)
    }

    const handlePhotoRow = id => {
        if (id === photoRow) {
            return setPhotoRow("");
        }
        setEditRow("");
        return setPhotoRow(id);
    }

    const onChange = e => {
        setEditing(true)
        const { name, value } = e.target;
        if (name === "labNumber" || name === "labNumberEnd") {
            setFormData({ ...formData, [name]: parseInt(value) })
        } else {
            setFormData({ ...formData, [name]: value })
        }
        delete errors[name];
        setErrors(errors);
    }

    const handlePhotoDelete = async () => {
        const imageID = photoDeleteID;
        setLoading(true);
        setFilterAfterEdit(true)
        const updatedImages = images.map(element => ({ ...element, status: element.id === imageID ? "DELETE" : element.status }))
        const variables = {
            ...formData,
            photos: images.filter(element => !element.url).map(({ image }) => image.split("data:image/jpeg;base64,").pop()),
            photoEdits: updatedImages.map(({ id, token, status, photoVersion }) => ({ id, token, status, photoVersion })),
            userEmail: userEmail
        }
        let editSampleQuery = `
        mutation{
            editSampleCapture(
                fiscalYear: ${JSON.stringify(variables.fiscalYear)},
                id: ${JSON.stringify(variables.id)},
                lab: ${JSON.stringify(variables.lab)},
                labNumber: ${variables.labNumber},
            `
        // photoEdits:${JSON.stringify(variables.photoEdits)}
        if (variables.photoEdits.length) {
            editSampleQuery += `photoEdits: [
                `
            for (let index = 0; index < variables.photoEdits.length; index++) {
                const element = variables.photoEdits[index];
                // console.log(element);
                editSampleQuery += `{
                        id:${JSON.stringify(element.id)},
                        status:${element.status},
                        token:${JSON.stringify(element.token)},
                        photoVersion: ${JSON.stringify(element.photoVersion)}
                    }
                    `
            }
            editSampleQuery += `],
                `
        }
        editSampleQuery += `photos: [${variables.photos.map((x) => { return JSON.stringify(x) })}],
                sampleType: ${JSON.stringify(variables.sampleType)},
                userEmail: ${JSON.stringify(variables.userEmail)}
            ) {
                error
                ok
                sampleCapture {
                    id
                    fiscalYear
                    lab
                    labNumber
                    photos {
                        id
                        token
                        url
                    }
                    sampleType
                }
            }
        }`
        const editSampleQueryRes = await fetch(sampleCapGQLURI, {
            method: 'POST',
            body: JSON.stringify({ query: editSampleQuery }),
            headers: { 'Content-type': 'application/json' }
        })
        const editSampleQueryResponse = await editSampleQueryRes.json()
        if (editSampleQueryResponse.data && editSampleQueryResponse.data.editSampleCapture && editSampleQueryResponse.data.editSampleCapture.ok) {
            getSampleCaptures();
        }

        setImages(updatedImages);
        setFileInputKey(fileInputKey + 1);
        setPhotoDeleteID("");
        setLoading(false);
        setShowConfirmationModal(false);
        setShowSuccess(true);
        setTimeout(() => { setShowSuccess(false) }, 4000);
    }

    const handleConfirmationDelete = (id) => {
        setPhotoDeleteID(id);
        setShowConfirmationModal(true);
    }

    const handlePhotoAddToGrid = () => {
        if (fileInput.current && fileInput.current.files && fileInput.current.files.length > 0) {
            setEditing(true);
            const file = fileInput.current.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImages([...images, {
                    id: uuidv4(),
                    image: reader.result,
                    file: fileInput.current.files[0],
                    status: "NEW"
                }]);
            }
            delete errors.images;
            setErrors(errors);
        }
    }

    const handleCancel = id => {
        setEditing(false);
        if (id === editRow) {
            return setEditRow("");
        }
        const rowToEdit = filteredResources.find(element => element.id = id);
        const imagesToEdit = rowToEdit.photos.map(element => ({ ...element, status: "KEEP" }));
        setFormData(rowToEdit);
        setImages(imagesToEdit);
        setFileInputKey(fileInputKey + 1);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const formErrors = {};
        if (!formData.lab) {
            formErrors.lab = { error: true, message: "Lab is required." };
        }
        if (!formData.sampleType) {
            formErrors.sampleType = { error: true, message: "Sample Type is required." };
        }
        if (!formData.labNumber) {
            formErrors.labNumber = { error: true, message: "Lab Number is required." };
        }
        if (images.length === 0) {
            formErrors.images = { error: true, message: "At least 1 photo is required." };
        }
        if (Object.keys(formErrors).length > 0) {
            setShowMissingInfoModal(true);
            setErrors(formErrors);
            return;
        }

        setLoading(true);
        setFilterAfterEdit(true);

        const variables = {
            ...formData,
            photos: images.filter(element => !element.url).map(({ image }) => image.split("data:image/jpeg;base64,").pop()),
            //TODO: should this be images instead of updatedImages?
            photoEdits: images.map(({ id, token, status, photoVersion }) => ({ id, token, status, photoVersion })),
            // photoEdits: updatedImages.map(({ id, token, status, photoVersion }) => ({ id, token, status, photoVersion })),
            userEmail: userEmail
        }

        let editSampleQuery = `
        mutation{
            editSampleCapture(
                fiscalYear: ${JSON.stringify(variables.fiscalYear)},
                id: ${JSON.stringify(variables.id)},
                lab: ${JSON.stringify(variables.lab)},
                labNumber: ${JSON.stringify(variables.labNumber)},
        `
        // photoEdits: {
        //     id: ${JSON.stringify(variables.photoEdits[0].id)},
        //     status: ${variables.photoEdits[0].status},
        //     token: ${JSON.stringify(variables.photoEdits[0].token)},
        //     photoVersion: ${JSON.stringify(variables.photoEdits[0].photoVersion)}
        // }
        if (variables.photoEdits.length) {
            editSampleQuery += `photoEdits: [
                `
            for (let index = 0; index < variables.photoEdits.length; index++) {
                const element = variables.photoEdits[index];
                // console.log(element);
                if (element.status !== 'NEW') {
                    editSampleQuery += `{
                        id:${JSON.stringify(element.id)},
                        status:${element.status},
                        token:${JSON.stringify(element.token)},
                        photoVersion: ${JSON.stringify(element.photoVersion)}
                    }
                    `
                }
            }
            editSampleQuery += `],
                `
        }
        editSampleQuery += `photos: [${variables.photos.map((x) => { return JSON.stringify(x) })}],
                sampleType: ${JSON.stringify(variables.sampleType)},
                userEmail: ${JSON.stringify(variables.userEmail)}
            ) {
                ok
                sampleCapture {
                    id
                    fiscalYear
                    lab
                    labNumber
                    sampleType
                    photos {
                        id
                        token
                        url
                    }
                }
            }
        }`
        const editSampleQueryFetch = await fetch(sampleCapGQLURI, {
            method: 'POST',
            body: JSON.stringify({ query: editSampleQuery }),
            headers: { 'Content-type': 'application/json' }
        })

        const editSampleQueryResponse = await editSampleQueryFetch.json();

        if (editSampleQueryResponse.data && editSampleQueryResponse.data.editSampleCapture && editSampleQueryResponse.data.editSampleCapture.ok) {
            getSampleCaptures();
            const rowToEdit = editSampleQueryResponse.data.editSampleCapture.sampleCapture;
            const imagesToEdit = rowToEdit.photos.map(element => ({ ...element, status: "KEEP" }));

            setEditing(false);
            setFormData(rowToEdit);
            setImages(imagesToEdit);
            setEditRow(rowToEdit.id);
            setLoading(false);
            handlePhotoRow(editRow);
            setShowSuccess(true);
            setTimeout(() => { setShowSuccess(false) }, 4000);
        }
    }

    const handleCloseMissingInfo = () => setShowMissingInfoModal(false);
    const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
    const handleCloseLabFilterModal = () => setLabFilterModal(false);
    const handleCloseSampleTypeFilterModal = () => setSampleTypeFilterModal(false);
    const handleLabFilter = (filter) => setLabFilterLabel(filter);
    const handleSampleTypeFilter = (filter) => setSampleTypeFilterLabel(filter);
    const handleResetFilter = () => {
        setFilteredResources(showImages);
        setLabFilterLabel("");
        setSampleTypeFilterLabel("");
        ref.current.value = "";
    }
    const openFilterModal = (modal) => {
        switch (modal) {
            case "labState":
                setLabFilterModal(true)
                break;
            case "sampleTypeState":
                setSampleTypeFilterModal(true);
                break;
            default:
                setLabFilterModal(false)
                setSampleTypeFilterModal(false);
                break;
        }
    }

    useEffect(() => {
        if (showImages !== filteredResources && capArrayChanged === true) {
            if (!filterAfterEdit) {
                setFilteredResources(showImages)
                setCapArrayChanged(false)
                handleFilterForLab("All")
            } else {
                setCapArrayChanged(false)
                let filterByLab = [];
                let filteringDone = [];
                let allResources = [];
                if (labFilterLabel !== "All") {
                    const filterForLab = labFilterLabel.toLowerCase();
                    const res = (showImages.filter((x) => {
                        return (getNestedStrings(x, 1).some((y) => {
                            return (y.toLowerCase().includes(filterForLab.toLowerCase()))
                        }))
                    }));
                    allResources = res;  // show result object(s)
                    filteringDone = res;
                    filterByLab = res;
                }
                if (sampleTypeFilterLabel !== "All" && labFilterLabel === "All") {
                    const filterForSampleType = sampleTypeFilterLabel.toLowerCase();
                    const res = (showImages.filter((x) => {
                        return (getNestedStrings(x, 1).some((y) => {
                            return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
                        }))
                    }));
                    allResources = res  // show result object(s)
                    filteringDone = res
                } else if (sampleTypeFilterLabel !== "All" && labFilterLabel !== "All") {
                    const filterForSampleType = sampleTypeFilterLabel.toLowerCase();
                    const res = (filterByLab.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                        return (getNestedStrings(x, 1).some((y) => {
                            return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
                        }))
                    }));
                    allResources = res // show result object(s)
                    filteringDone = res
                }
                if (allResources.length) {
                    setFilteredResources(allResources);
                } else {
                    setFilteredResources(showImages)
                }
                setFilterCombine(filteringDone);
                setLabFilter(filterByLab);
                setFilterAfterEdit(false);
            }
        }
        // eslint-disable-next-line
    }, [showImages, filteredResources, capArrayChanged, labFilterLabel])

    useEffect(() => {
        if (labFilterLabel === "") {
            setLabButtonLabel("All")
        } else {
            setLabButtonLabel(labFilterLabel)
        }
        handleCookie();
        // eslint-disable-next-line
    }, [labFilterLabel])

    useEffect(() => {
        if (sampleTypeFilterLabel === "") {
            setSampleTypeButtonLabel("All")
        } else {
            setSampleTypeButtonLabel(sampleTypeFilterLabel)
        }
    }, [sampleTypeFilterLabel])

    useEffect(() => {
        getSampleCaptures();
        const email = getEmail();
        setUserEmail(email);
        if (cookies.labFilter) {
            if (cookies.labFilter === "All") {
                setLabFilterLabel("");
            } else {
                setLabFilterLabel(cookies.labFilter);
            }
        } else {
            setLabFilterLabel("")
        }
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <TempMessageModalComp isOpen={loading} isSuccessMessage={false} message={"Loading"} />;
    }
    return (
        <>
            {showSuccess && <TempMessageModalComp isOpen={showSuccess} isSuccessMessage={true} message={"Success"} />}
            {showMissingInfoModal && Object.keys(errors).length > 0 && (
                <MissingInfoModalComp errors={errors} handleCloseModal={handleCloseMissingInfo} isOpen={showMissingInfoModal} />
            )}
            <div style={{ "marginTop": "5px" }}>
                <div className="card mx-auto">
                    <div className="card-body" style={{ marginTop: "-14px" }}>
                        <ConfirmationModalComp isOpen={showConfirmationModal} handleCloseModal={handleCloseConfirmationModal} handleSubmit={handlePhotoDelete} title={"DELETE WARNING"} message={["ARE YOU SURE YOU WANT TO DELETE THIS IMAGE?"]} submitButtonLabel={"DELETE"} />
                        <FilterModalComp
                            isOpen={labFilterModal}
                            handleCloseModal={handleCloseLabFilterModal}
                            title={"Filter By Lab"}
                            listItems={['All', 'Amarillo', 'Dodge City', 'Hastings']}
                            filter={handleLabFilter}
                            filterList={labFilterLabel}
                            handleFiltering={handleFilterForLab}
                        />
                        <FilterModalComp
                            isOpen={sampleTypeFilterModal}
                            handleCloseModal={handleCloseSampleTypeFilterModal}
                            title={"Filter By Sample Type"}
                            listItems={['All', 'Feed', 'Fertilizer', 'Plant', 'Soil', 'Environmental', 'Other']}
                            filter={handleSampleTypeFilter}
                            filterList={sampleTypeFilterLabel}
                            handleFiltering={handleFilterForSampleType}
                        />
                        <div className="table-head" style={{ marginBottom: "12px" }}>
                            <div>
                                <i style={{ position: "absolute!important", opacity: ".99", marginLeft: "12px", marginRight: "-32px", marginTop: "8px" }}>
                                    <img src={filterIcon} alt="filter" />
                                </i>
                                <input style={{ borderRadius: "5px", border: "1px solid lightgrey", textAlign: "left", textIndent: "36", display: "inline", paddingLeft: "30px", marginLeft: "2px", position: "absolute!important" }} className="input-field" name="search" type="text" ref={ref} onKeyUp={handleSearchKeyUp} placeholder="Start typing to filter..." />
                            </div>
                            <div>
                                <button className="labStateBtn" variant="outline-dark" style={{ alignItems: "center", display: "flex" }} onClick={() => { openFilterModal("labState") }}>
                                    {labButtonLabel}
                                    &nbsp;
                                    <img src={code} alt="code" />
                                </button>
                            </div>
                            <div>
                                <button className="sampleTypeStateBtn" variant="outline-dark" style={{ alignItems: "center", display: "flex" }} onClick={() => { openFilterModal("sampleTypeState") }}>
                                    {sampleTypeButtonLabel}
                                    &nbsp;
                                    <img src={code} alt="code" />
                                </button>
                            </div>
                            <div>
                                <button className="reset-filter-btn" onClick={handleResetFilter}>
                                    Reset Filters
                                </button>
                            </div>
                        </div>
                        <div className="table-head">
                            <div>Lab Number</div>
                            <div>Lab</div>
                            <div>Sample Type</div>
                            <div></div>
                        </div>
                        {filteredResources.length === 0 &&
                            <div style={{ textAlign: 'center' }}>No recent sample captures</div>
                        }
                        {filteredResources && filteredResources.map(el => (
                            <React.Fragment key={el.id}>
                                <div className="table-row">
                                    <div>{el.labNumber}</div>
                                    <div>{el.lab}</div>
                                    <div>{el.sampleType}</div>
                                    <div className="action-col">
                                        <div aria-hidden="true" className="photo-action" onClick={() => handlePhotoRow(el.id)}>
                                            {el.photos.length === 1
                                                ? `${photoRow === el.id ? 'Hide' : 'Show'} ${el.photos.length} Photo`
                                                : `${photoRow === el.id ? 'Hide' : 'Show'} ${el.photos.length} Photos`}
                                            <img src={photoRow === "" ? chevronDown : chevronUp} alt="down" color="rgb(30,144,255)" {...photoRow === el.id && { rotate: 180 }} />
                                        </div>
                                        {/* <div className="divider" /> */}
                                        <hr />
                                        <div aria-hidden="true" className="photo-action" onClick={() => handleEditRow(el.id)}>
                                            Edit
                                        </div>
                                    </div>
                                </div>
                                {photoRow === el.id && (
                                    <div className="table-row-extra">
                                        <PhotoGridComp
                                            images={el.photos}
                                            handlePhotoDelete={handleConfirmationDelete}
                                            handlePhotoEnlarge={handleShowModal}
                                            readOnly={true}
                                            showAutoPhotoTools={false}
                                            fileInput={fileInput}
                                            fileInputKey={fileInputKey}
                                            handlePhotoAddToGridFileInput={handlePhotoAddToGrid}
                                            errors={errors}
                                        />
                                    </div>
                                )}
                                {editRow === el.id && (
                                    <form className="table-row-extra" onSubmit={e => handleSubmit(e)}>
                                        <LabFormInputsComp
                                            yearsList={["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025"]}
                                            labList={["Amarillo", "Dodge City", "Hastings"]}
                                            sampleTypeList={["Feed", "Fertilizer", "Plant", "Soil", "Environmental", "Other"]}
                                            errors={errors}
                                            formData={formData}
                                            onChange={onChange}
                                            preventMinus={preventMinus}
                                            withLocksAndRange={{}}
                                        />
                                        <hr />
                                        <PhotoGridComp
                                            images={images.filter(image => image.status !== 'DELETE')}
                                            handlePhotoDelete={handleConfirmationDelete}
                                            handlePhotoEnlarge={handleShowModal}
                                            readOnly={false}
                                            showAutoPhotoTools={false}
                                            fileInput={fileInput}
                                            fileInputKey={fileInputKey}
                                            handlePhotoAddToGridFileInput={handlePhotoAddToGrid}
                                            errors={errors}
                                        />
                                        <div className="edit-sample-capture-buttons">
                                            <button className="secondary" type="button" onClick={() => handleCancel(el.id)}>Cancel</button>
                                            {editing &&
                                                <button className="submit-button" buttontype="submit">Save</button>
                                            }
                                            {!editing &&
                                                <button className="secondary" type="button">Save</button>
                                            }
                                        </div>
                                    </form>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recent;